<template>
  <div class="list-simple">
    <ul v-if="filteredItems.length" class="list-simple__list">
      <li
        v-for="item in filteredItems"
        :key="item.id"
        :class="['list-simple__item', { 'list-simple__item_active': item.id === value }]"
        @click="setValue(item.id)"
      >
        <div class="list-simple__left">
          <div class="list-simple__flags">
            <atomic-image
              v-if="item.icon"
              class="list-simple__icon"
              :src="item.icon"
              :not-lazy="true"
              :defaultImage="DEFAULT_IMG"
            />
          </div>
          {{ item.name }}
        </div>
        <div v-if="item.amount" class="list-simple__right">
          <div class="list-simple__current-value">
            {{ formatAmountBasedOnCurrency(item?.currency, item.amount?.currenValue) }}
          </div>
          <div v-if="item.amount.baseValue" class="list-simple__base-value">
            <span v-if="item.amount.baseCurrencySymbol">{{ item.amount.baseCurrencySymbol }}</span>
            <atomic-icon v-else id="usdt" />
            {{ formatValue(item.amount.baseValue) }}
          </div>
        </div>
      </li>
    </ul>
    <span v-else class="list-simple__empty">
      {{ getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.empty.text') }}
    </span>
  </div>
</template>

<script lang="ts" setup>
  import type { ISimpleListItem } from '~/types';
  import { DEFAULT_IMG } from '@skeleton/consts/staticPath';
  import { storeToRefs } from 'pinia';

  const props = defineProps<{
    value: string;
    searchValue: string;
    list: ISimpleListItem[];
  }>();

  const emit = defineEmits(['update:value', 'selected']);
  const globalStore = useGlobalStore();
  const { fieldsSettings, defaultLocaleFieldsSettings } = storeToRefs(globalStore);
  const { getContent } = useProjectMethods();

  const filteredItems = computed(() => {
    const searchQuery = props.searchValue.toLowerCase().trim();

    if (!searchQuery) {
      return props.list;
    }

    return props.list.filter(item => {
      return item.name.toLowerCase().includes(searchQuery);
    });
  });

  const setValue = (inputValue: string) => {
    emit('update:value', inputValue);
    emit('selected', inputValue);
  };
</script>

<style lang="scss" src="~/assets/styles/components/list/simple.scss" />
